import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Divider, Input, InputRef, message, Space, Tag } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { ContentTagsT } from './types';
import { useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';

export const ContentTags: FC<ContentTagsT> = ({
  id,
  topDivider = false,
  bottomDivider = false,
}) => {
  const [parsedMetaData, setParsedMetaData] = useState<object | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);

  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const { data, refetch } = useQuery<any>({
    queryKey: [queryNames.FULFILMENT_CONTENTS_GET, 'metaTags', id],
    meta: {
      token,
      getAllData,
      columnParams: ['metadata'],
      additionalSearchParams: `?id=${id}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (onSuccessData) => {
      if (onSuccessData && onSuccessData.hasOwnProperty('error')) {
        message.error(`Server Error ${onSuccessData.error?.message}`);
        return;
      } else {
        message.success('Tags was successfully updated');
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (data && data?.data?.metadata) {
      const parsedData = JSON.parse(data.data.metadata);
      if (parsedData?.tags && Array.isArray(parsedData.tags)) {
        setTags(parsedData.tags);
        setParsedMetaData(parsedData);
      }
    }
  }, [data]);

  const updateTagsMutation = (updatedTags: string[]) => {
    let contentMeta;
    if (parsedMetaData && typeof parsedMetaData === 'object') {
      contentMeta = { ...parsedMetaData, tags: updatedTags };
    } else {
      contentMeta = { tags: updatedTags };
    }
    const mutateData = {
      id: id,
      metadata: JSON.stringify(contentMeta),
    };

    mutate({
      data: mutateData,
      token,
      otherProps: queryNames.FULFILMENT_CONTENTS,
      method: 'PUT',
    });
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    updateTagsMutation(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.toLowerCase());
  };

  const handleInputConfirm = () => {
    const value = inputValue.trim().toLowerCase();
    if (value && tags.indexOf(value) === -1) {
      const valuesArr = value.replaceAll(', ', ',').split(',');
      const newTagsArr = [...tags, ...valuesArr];
      setTags(newTagsArr);
      updateTagsMutation(newTagsArr);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const tagChild = tags.map((tag: string) => (
    <Tag
      key={tag}
      className={styles.tag}
      title={tag.length > 15 ? tag : undefined}
      closable
      color="blue"
      onClose={(e) => {
        e.preventDefault();
        !isLoading && handleClose(tag);
      }}
    >
      {tag.length > 15 ? `${tag.substring(0, 14)}...` : tag}
    </Tag>
  ));

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {topDivider && (
        <Divider
          style={{ margin: '10px 0', borderColor: '#D5DBDF' }}
          orientation="left"
          plain
        >
          Tags
        </Divider>
      )}
      <div>
        <Space wrap>{tagChild}</Space>
      </div>
      <div className={styles.newWrapper}>
        {inputVisible ? (
          <>
            <Input
              ref={inputRef}
              placeholder="Separate by comma to add multiple tags"
              type="text"
              size="small"
              className={styles.newTagInput}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          </>
        ) : (
          <Tag
            onClick={isLoading ? () => {} : showInput}
            className={styles.newTag}
            icon={isLoading ? <SyncOutlined spin /> : <PlusOutlined />}
          >
            {isLoading ? 'Saving...' : 'Add new Tag'}
          </Tag>
        )}
      </div>
      {bottomDivider && (
        <Divider style={{ margin: '10px 0', borderColor: '#D5DBDF' }} />
      )}
    </div>
  );
};
